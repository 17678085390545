import { useI18n } from 'vue-i18n';
import { useSuspenseQuery } from '~/queries/useSuspenseQuery';
import type { IMarketplaceCategoriesResponse } from '~/types/marketplaceTypes';

export const useMarketplaceCategoriesQuery = () => {
  const { locale } = useI18n();

  const query = useSuspenseQuery({
    queryKey: ['marketplace', 'categories'],
    queryFn: async () => {

      return await $apiFetch<IMarketplaceCategoriesResponse>(
        `/api/marketplace/categories`,
        {
          params: { lang: locale.value },
        }
      );
    },
  });

  return query;
};
