import { keepPreviousData } from "@tanstack/vue-query";

export const useMarketplaceSearchQuery = (search: string, locale: string) => {
  return useSuspenseQuery({
    queryKey: ["marketplace", "offers", search],
    queryFn: async () => {
      return await $apiFetch(`/api/marketplace/full-search`, {
        params: { lang: locale, search },
      });
    },
    placeholderData: keepPreviousData,
  });
};
