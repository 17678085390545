import { keepPreviousData } from "@tanstack/vue-query";
import type {
  IMarketplaceOfferCategory,
  IMarketplaceOffersResponse,
} from "~/types/marketplaceTypes";

export const useMarketplaceOffersQuery = (
  filters: Ref<Array<IMarketplaceOfferCategory>>,
  locale: string,
) => {
  return useSuspenseQuery({
    queryKey: ["marketplace", "offers", filters],
    queryFn: async () => {
      return await $apiFetch<IMarketplaceOffersResponse>(
        `/api/marketplace/offers`,
        {
          params: { lang: locale, filters: JSON.stringify(filters) },
        },
      );
    },
    placeholderData: keepPreviousData,
  });
};
